import { useState } from 'react'
import logo_ from '../../assets/images/logo.png'
import hambuger_icon_white from '../../assets/images/hambuger-icon-white.png'
import { Link } from 'react-scroll'
import './navbarn.scss'

const NavbarN = () => {

  const info_url = '#info'

  const [click, setClick] = useState(false)

  //const handleClick = () => setClick(!click)
  const closeMenu = () => setClick(false)

  const [mobileMenu, setMobileMenu] = useState(false)

  const toggleMenu = () => {
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true)
  }

  return (

    <nav className=''>
      <a href={info_url}>
        <img src={logo_} alt="Podotherapie - Köln Logo" className='logo' />
      </a>

      <ul className={`${mobileMenu ? '' : 'hide-mobile-menu'} ${click ? 'active' : ''}`}>
        <li><Link activeClass="active" spy={true} to='hauptseite' smooth={true} offset={-300} duration={500} onCklick={closeMenu}>Hauptseite</Link></li>

        <li><Link activeClass="active" spy={true} to='uebermich' smooth={true} offset={-70} duration={500} onCklick={closeMenu}>Über mich</Link></li>

        <li><Link activeClass="active" spy={true} to='behandlungsablauf' smooth={true} offset={-70} duration={500} onCklick={closeMenu}>Behandlungsablauf</Link></li>

        <li><Link activeClass="active" spy={true} to='galerie' smooth={true} offset={-70} duration={500} onCklick={closeMenu}>Galerie</Link></li>

        <li><Link activeClass="active" spy={true} to='kontakt' smooth={true} offset={-60} duration={500} onCklick={closeMenu}>Kontakt</Link></li>

        <li className="nav-item dropdown">
          <Link activeClass="active" spy={true} to='' smooth={true} duration={500} onCklick={closeMenu} className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Impressum
          </Link>

          <ul className="dropdown-menu">
            <li><Link activeClass="active" spy={true} to='impressum' smooth={true} offset={-40} duration={500} onCklick={closeMenu} >Impressum</Link></li>

            <li><Link activeClass="active" spy={true} to='datenschutz' smooth={true} offset={-40} duration={500} onCklick={closeMenu}  >Datenschutzerklärung</Link></li>

            <li><Link activeClass="active" spy={true} to='aufklaerung' smooth={true} offset={-40} duration={500} onCklick={closeMenu}>Aufklärung</Link></li>
          </ul>
        </li>

      </ul>
      <img src={hambuger_icon_white} className='hambuger-icon-white' onClick={toggleMenu} alt="Navigation Hamburger Icon" />
    </nav>
  )
}

export default NavbarN
