import React from 'react'
import './ContactBoxEmail.scss'
import email_icon from '../../../assets/images/email-icon-red.png'
const ContactBoxEmail = () => {

    return (
   
        /* <!-- Email --> */
        <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
            <div className="contact-box_icon">
                <img className='email-icon' src={email_icon} alt="EMail Icon" />
            </div>
            <div className="contact-box_info">
                <a href="mailto:henry@podotherapie-koeln.de" className="contact-box_info--title">henry@podotherapie-koeln.de</a>
                <p className='contact-box_info--subtitle'>Senden Sie uns jederzeit Ihre Anfrage!</p>
            </div>
      </div>
  )
}

export default ContactBoxEmail
