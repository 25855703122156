import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'

import './IconInstagram'

const IconInstagram = (props) => {
    return <InstagramIcon {...props} />
}

export default IconInstagram
