import React from 'react'
import userConfigs from '../../userConfigs'
import { IconFacebook, IconInstagram, IconLinkedIn } from '../../components/Icons/'

import './UeberMich.scss'

import henry from '../../assets/images/henry.jpg'
import qr_whatsapp from '../../assets/images/qr-left.png'

const UeberMich = () => {

  // map kann nur mit Arrays arbeiten nicht mit Objekten Reihe
  // bei traversieren von Objekten mit map() gibt es Fehler meldungen
  const iconsArray = Object.values(userConfigs.icons)

  // Funktion, die basierend auf dem Icon-Namen die entsprechende Komponente zurückgibt
  const renderIcon = (iconName) => {
    switch (iconName) {
      case 'facebook':
        return <IconFacebook />;
      case 'instagram':
        return <IconInstagram />;
      case 'linkedin':
        return <IconLinkedIn />;
      default:
        return null; // Für den Fall, dass kein passender Name gefunden wird
    }
  }

  return (
    <section id='uebermich' className='uebermich'>
      <div className="container pt-5 pb-5">
        <div className="row">
          <h1 className='display-1 fw-bold'>Über mich</h1>
        </div>
        <div className="row">
          <div className="col-6">
            <h1>Henry Posmontier</h1>
            <h4>Podologe, Heilpraktiker & Sektoraler Heilpraktiker (SHP) Podologie</h4>
            <p>Liebe Besucher auf meiner Webseite, mein Name ist Henry Posmontier.</p>
            <p>Ich bin erfreut Sie als baldigen Patienten bei mir in der Praxis begrüßen zu dürfen. </p>
            <img className='img-fluid rounded img1' src={qr_whatsapp} alt='WhatsApp Rufnummer' />
          </div>
          <div className="col-6">
            <img className='img-fluid rounded-5 img-groß' src={henry} alt="Henry Posmontier Bild" />
          </div>
        </div>
        <div className="row">

        </div>
        <div className="col-12">
          {
            iconsArray.map((icon, index) => {
              const relAttribute = icon.target === '_blank' ? 'noopener noreferrer' : '';
              return (
                <a
                  key={index}
                  href={icon.link}
                  style={{ color: icon.farbe, marginRight: '10px' }}
                  target={icon.target}
                  rel={relAttribute}
                >
                  {renderIcon(icon.name)}
                </a>
              );
            })
          }
        </div>
        <div className="row pt-5 pb-3">
          <div className="col-lg-6">
            <h2>Vita</h2>
          </div>
          <div className="col-lg-6">
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-lg-6">

            <p>1994 Fachabitur.</p>
            <p>1998-1999 Ausbildung zum staatlich anerkannten Fuß- und Handpfleger und Nageldesigner in Budapest.</p>
            <p>Seit 1999 Tätigkeit als Fußpfleger.</p>
            <p>2003-2004 Ausbildung zum Kosmetiker und zur medizinischen Fußpflege bei Traute De Lorenzi in Köln.</p>
            <p>2005-2006 Naturarzt/Heilpraktiker Fachausbildung zum alternativen Bewegungs- und Massagetherapeuten und parallel dazu eine Ausbildung zum medizinischen Masseur in Budapest.</p>
            <p>2008-2011 Universitätslehrgang zur Sozialpsychiatrischen Fachkraft an der Károli Gáspár Universität der Reformierten Kirche Ungarns in Budapest.</p>
          </div>
          <div className="col-lg-6">
            <p>2009-2011 Ausbildung zum staatlich anerkannten Podologen in Köln. Nach erfolgreich abgelegter Prüfung bis heute selbständiger Podologe mit eigener Praxis in Köln.</p>
            <p>2013 Erlaubnis der Berufsbezeichnung Heilpraktiker (Psychotherapie), Gesundheitsamt Düsseldorf.</p>
            <p>2019 Erlaubnis der Berufsbezeichnung Heilpraktiker (Podologie) Gesundheitsamt Köln.</p>
            <p>2022 Erlaubnis der Berufsbezeichnung Heilpraktiker (Gesundheitsamt Nürnberg).</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UeberMich
