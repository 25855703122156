import React from 'react'
import './backToTopButton.scss'


const BackToTopButton = () => {

    const scrollTop = (e) => {
        e.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className="col-md-12">
            <div className="scroll-top">
                {/* Verwende onClick anstatt href */}
                <a onClick={scrollTop} className="shadow btn-primary rounded-circle back-to-top" href>
                    <i className="fa fa-angle-up"></i>
                </a>
            </div>
        </div>
    )
}

export default BackToTopButton
