import React from 'react'
import './CopyRight.scss'

const CopyRight = () => {

  const year = new Date().getFullYear()
  //const toni_cv = `${process.env.PUBLIC_URL}/assets/pdfs/Toni Zeidler - Lebenslauf 2024_v6.pdf`;

  return (
    /* <!-- Copy Right --> */
    <div className="footer-botton pt-5 pb-5">
      <div className="container">
        <div className="row text-center text-white">
          <div className="col-12">
            <div className="footer-bottom_copyright">
              © Copyright {year} <a href="https://www.egosanto.de" target='_blank' rel='noopener noreferrer'>egosanto</a> | Created by Toni Zeidler
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CopyRight
