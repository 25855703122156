import React from 'react'
import './Instagram.scss'

const Instagram = () => {

    const linkToInstagram = 'https://www.instagram.com/henrypodotherapie/'

    return (
        <>
            <a href={ linkToInstagram }><i className="fab fa-instagram"></i></a>
        </>
    )
}

export default Instagram
