import React from 'react'
import './Facebook.scss'

const Facebook = (props) => {

    const linkToFacebook = 'https://www.facebook.com/profile.php?id=61553932202588/'

    return (
        <>
            <a href={ linkToFacebook }><i className="fab fa-facebook"></i></a>
        </>
    )
}

export default Facebook
