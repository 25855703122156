import React from 'react'
import './Behandlungsablauf.scss'

import henryFuß from '../../assets/images/image-480x480.jpg'

const Behandlungsablauf = () => {

  return (
    <section id='behandlungsablauf' className='behandlungsablauf'>
      <div className="container pt-5 pb-5">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <h1 className='display-1 fw-bold'>Behandlungsablauf</h1><hr />
          </div>
          <div className="col-lg-6">
            <img className='img-fluid rounded-4 img-1' src={henryFuß} alt="Henry im Behandlung" />
          </div>
          <div className="col-lg-6">
            <ul>
              <li>Anamnesegespräch</li>
              <li>Fußbad</li>
              <li>Nägel kürzen</li>
              <li>Polieren und Schleifen der Nägel</li>
              <li>Hornhaut abtragen</li>
              <li>Eincremen und Massieren der Füße</li>
            </ul>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Ich als Podologe arbeite sowohl an kranken (z.B. beim Diabetiker), als auch an gesunden Füßen.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Dies dient zur erhaltenden und vorbeugenden Maßnahme.   In der Regel führe ich bei den Neupatienten eine ausführliche Anamnese, Sichtbefund und eine funktionelle Untersuchung der Füße durch.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Je nach Zustand der Füße und ob die jeweilige Person einen Fußbad haben möchte, biete ich in der Regel einen Fußbad an. Nach Abtrocknen der Füße fange ich mit der eigentlichen Fußbehandlung (Hornhautverdickung abtragen, Nagelfalz sondieren, Nägel schneiden und entgräten (feilen) , Behandlung eingewachsener Nägel (Unguis incarnatus/Onychocryptosis), Hühneraugen (Clavi) Entfernung, Abschleifen mykotischer Nägel (Nagelpilz), Behandlung rissiger Fersen, usw.) an.</p>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Behandlungsablauf
