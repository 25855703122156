import './App.scss'

import NavbarN from './components/NavbarN'
import Hauptseite from './pages/Hauptseite'
import Behandlungsablauf from './pages/Behandlungsablauf'
import Galerie from './pages/Galerie'
import Info from './pages/Info'
import UeberMich from './pages/UeberMich'

import Kontakt from './pages/Kontakt'
import Impressum from './pages/Impressum'
import Datenschutzerklaerung from './pages/Datenschutzerklaerung'
import Aufklaerung from './pages/Aufklaerung'
import Footer from './components/Footer'
import BackToTopButton from './components/BackToTopButton'

function App() {

  return (
    <div className='App'>
      <NavbarN/>
      <Hauptseite/>
      <UeberMich/>
      <Behandlungsablauf/>
      <Galerie/>
      <Kontakt/>
      <Info/>
      {/* */}
      <Impressum/>
      <Datenschutzerklaerung/>
      <Aufklaerung/>
      <BackToTopButton/>
      <Footer/>
      
    </div>
  )
}

export default App
