import React from 'react'
import './Aufklaerung.scss'

const Aufklaerung = () => {
  return (
    <section id='aufklaerung' className='aufklaerung'>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-12">
            <h1 className='display-1'>Aufklärung</h1><hr />
            <div>
              <h2>Hinweis:</h2>
              <p>Gesetzlich bin Ich dazu verpflichtet Patienten darüber aufzuklären,  dass sie sich mit entzündeten Zehennägel, Eiter und wildem Fleisch an einen Facharzt oder Chirurgen wenden sollten. </p>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Aufklaerung
