import React from 'react'
import BusinessIcon from '@mui/icons-material/Business'
import './IconAddress.scss'

// Du kannst hier auch props übergeben, um das Icon anzupassen (z.B. Größe, Farbe)
const IconAddress = (props) => {
  return <BusinessIcon {...props} />
}

export default IconAddress
