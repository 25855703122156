import { useRef } from 'react'

import './Kontakt.scss'
import 'leaflet/dist/leaflet.css'

import emailjs from '@emailjs/browser'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'

import podo_marker_bg from '../../assets/images/podo-marker-bg.png'

const Kontakt = () => {

  const refForm = useRef()

  const markers = [
    {
      geocode: [50.950779, 6.9666559],
      popUp: 'Henry ist hier'
    }
  ]

  const customIcon = new L.Icon({
    iconUrl: podo_marker_bg,
    iconSize: [99, 99]
  })

  const sendEmail = (e) => {
    e.preventDefault()
    // sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs
      .sendForm(
        'service_zf7j3z7',
        'template_vmjtgnb',
        refForm.current,
        'Tgq7VlTDZ5zqwsWWS'
      )
      .then(
        () => {
          alert('Ihre Kommentare wurden erfolgreich gesand. Danke.')
          window.location.reload(true)
        },
        () => {
          alert('Leider hat es nicht geklappt, versuchen Sie es nochmal.')
        }
      )
  }

  return (
    <section id='kontakt' className='kontakt'>
      <div className="container contact-page text-zone">

        <div className="row">
          <div className="col-12">
            <h1 className='display-5 p-5'>Terminabsprache und Preisanfrage nur telefonisch!</h1>
          </div>
        </div>

        <div className="row">
          <div className='col-lg-6 contact-form'>
            <form ref={refForm} onSubmit={sendEmail}>
              <ul>
                <li className='half'>
                  <input type='text' name='name' placeholder='Name' required />
                </li>
                <li className='half'>
                  <input type='email' name='email' placeholder='Email' required />
                </li>
                <li>
                  <input placeholder="Betref" type="text" name="subject" required />
                </li>
                <li>
                  <textarea placeholder='Kommentar' name='message' required></textarea>
                </li>
                <li>
                  <input type='submit' className='flat-button' value="SENDEN" />
                </li>
              </ul>
            </form>
          </div>

          <div id='map' className="col-lg-6 map-wrap">
            <div className="info-map">
              Henry Posmontier
              <br />
              Podologe
              <br />
              Konrad-Adenauer-Ufer 79-81
              <br />
              50668 Köln
              <br />
              <span> henry@podotherapie-koeln.de</span>
            </div>
            <MapContainer center={[50.950779, 6.9666559]} zoom={16}>

              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {markers.map((marker, index) => (
                <Marker key={index} position={marker.geocode} icon={customIcon}>
                  <Popup><h2>Henry Podotherapie Praxis ist hier</h2></Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>


        </div>
      </div>
    </section>
  )
}

export default Kontakt


