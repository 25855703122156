import './Footer.scss'

import ContactBoxWhatsApp from './ContactBoxWhatsApp'
import ContactBoxEmail from './ContactBoxEmail'
import ContactBoxAddress from './ContactBoxAddress'
import CopyRight from './CopyRight'
import SocialIconRow from '../SocialIconRow'

const Footer = () => {
       
    return (
        /* <!-- Footer --> */
        <footer className='footer'>
            <div className='container'>
                <div className="row">
                    {/* <!-- Mobile Number --> */}
                    <ContactBoxWhatsApp />

                    {/* <!-- Email --> */}
                    <ContactBoxEmail />

                    {/* <!-- Address --> */}
                    <ContactBoxAddress />
                </div>
                
                <div className="row col-md-12">
                    {/* <!-- Social Icon Row --> */}
                    <SocialIconRow />
                </div>

                <div className="row col-md-12">
                    {/* <!-- Impressum --><ImpresumAnd /> */}
                    
                </div>
                
                <div className="row col-md-12">
                    {/* <!-- Copy Right --> */}
                    <CopyRight />
                </div>
            </div>


        </footer> 
  )
}

export default Footer
