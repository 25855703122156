import React from 'react'
import PersonIcon from '@mui/icons-material/Person'
import './IconPerson.scss'

// Du kannst hier auch props übergeben, um das Icon anzupassen (z.B. Größe, Farbe)
const IconPerson = (props) => {
  return <PersonIcon {...props} />
}

export default IconPerson
