import React from 'react'
import './ContactBoxAddress.scss'
import adresse_icon from '../../../assets/images/address-icon-red.png'

const ContactBoxAddress = () => {
  return (
    <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
        <div className="contact-box_icon">
            <img className='adress-icon' src={adresse_icon} alt="Adresse Icon" />
        </div>
        <div class="contact-box_info">
            <a href="#kontakt" className="contact-box_info--title">Konrad-Adenauer-Ufer 79 / 81,<br/> 50668 Köln,<br/> 7-ter OG</a>
            <p className="contact-box_info--subtitle">Deutschland</p>
        </div>
    </div>
  )
}

export default ContactBoxAddress
