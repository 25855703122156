import React from 'react'
import './SocialIconRow.scss'
import Facebook from './SocialIcons/Facebook'
import Instagram from './SocialIcons/Instagram'
import LinkedIn from './SocialIcons/LinkedIn'

const SocialIconRow = () => {
    
  return (
    <>
      {/* <!-- SOCIAL MEDIA CONTACT --> */}
      <div className="footer-sm bg">
        <div className="row p-4 text-center text-white">
          <div className="col-lg-5 col-md-6 mb-4 mb-md-0">Vernetze dich mit mir.</div>
          <div className="col-lg-7 col-md-6">
            
            <Facebook />
            <Instagram />
            <LinkedIn />

          </div>
        </div>
      </div>
    </>
  )
}

export default SocialIconRow
