import React from 'react'
import './LinkedIn.scss'

const LinkedIn = () => {

    const linkToLinkedIn = 'https://de.linkedin.com/in/henry-posmontier-683a438b/'

    return (
        <>
            <a href={ linkToLinkedIn }><i className="fab fa-linkedin"></i></a>
        </>
    )
}

export default LinkedIn
