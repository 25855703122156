const userData = {

    googlereview: "https://www.google.com/maps/place/Podotherapie/@50.9507699,6.9666534,17z/data=!3m1!4b1!4m6!3m5!1s0x47bf24ff06143ce3:0x37c8903f6aadd176!8m2!3d50.9507699!4d6.9666534!16s%2Fg%2F11fzf7n49b?entry=ttu",
    wwwalt: "https://www.xn--podotherapiekln-ltb.de/",
    www: "https://www.podotherapie-koeln.de/",
    sitetitel: "Podotherapie in Köln",
    name: "Henry Posmontier",
    berufsbezeichnung: "Podologe & Sektoraler Heilpraktiker (SHP) Podologie",
    email: "info@henrypodotherapie.de",
    handy: "+49 176 705 666 43",
    tel: "+49 221 292 519 06",
    fax: " +49 221 292 519 07",
    straße: "Konrad-Adenauer-Ufer 79 - 81",
    plz: "50668",
    ort: "Köln",
    etage: "7. Etage",
    begrüßung: "Liebe Besucher auf meiner Webseite,mein Name ist Henry Posmontier. Bin Podologe, Heilpraktiker  & Sektoraler Heilpraktiker (SHP) der Podologie seit 2011.Ich bin erfreut Sie als baldigen Patienten bei mir in der Praxis begrüßen zu dürfen.",
    vita: [
        {
            jahr: "1994",
            beschreibung: "Fachabitur.",
        },
        {
            jahr: "1998-1999",
            beschreibung: "Ausbildung zum staatlich anerkannten Fuß- und Handpfleger und Nageldesigner in Budapest.",
        },
        {
            jahr: "Seit 1999",
            beschreibung: "Tätigkeit als Fußpfleger.",
        },
        {
            jahr: "2003-2004",
            beschreibung: "Ausbildung zum Kosmetiker und zur medizinischen Fußpflege bei Traute De Lorenzi in Köln.",
        },
        {
            jahr: "2005-2006",
            beschreibung: "Naturarzt/Heilpraktiker Fachausbildung zum alternativen Bewegungs- und Massagetherapeuten und parallel dazu eine Ausbildung zum medizinischen Masseur in Budapest.",
        },
        {
            jahr: "2008-2011",
            beschreibung: "Fernstudium zum Fachmann für Mentalhygiene an der Károli Gáspár Universität der Reformierten Kirche Ungarns in Budapest.",
        },
        {
            jahr: "2009-2011",
            beschreibung: "Ausbildung zum staatlich anerkannten Podologen in Köln. Nach erfolgreich abgelegter Prüfung bis heute selbständiger Podologe mit eigener Praxis in Köln.",
        },
        {
            jahr: "2013",
            beschreibung: "Überprüfung zum Heilpraktiker für Psychotherapie am Gesundheitsamt Düsseldorf nach 2 Jahre intensiver Vorbereitung.",
        },
        {
            jahr: "2019",
            beschreibung: "Erlaubnis der Berufsbezeichnung Heilpraktiker (Podologie) Gesundheitsamt Köln.",
        },
        {
            jahr: "2022",
            beschreibung: "Erlaubnis der Berufsbezeichnung Heilpraktiker (Gesundheitsamt Nürnberg).",
        },
        
    ],


    // Weitere Benutzerdaten
}

const iconsConfig = {
    facebook: {
      name: "facebook",
      link: "https://www.facebook.com/people/Podotherapie-Praxis-K%C3%B6ln/61553932202588/",
      farbe: "#ffffff",
      target: "_blank",
    },
    instagram: {
        name: "instagram",
        link: "https://www.instagram.com/henrypodotherapie/",
        farbe: "#ffffff",
        target: "_blank",
    },
    linkedin: {
        name: "linkedin",
        link: "https://www.linkedin.com/in/henry-posmontier-683a438b/",
        farbe: "#ffffff",
        target: "_blank",
    },
    // Weitere Icons konfigurieren
}

// Zusammenführung von iconConfig und userData in ein neues Objekt
const userConfigs = {
    icons: iconsConfig,
    userData: userData,
}

export default userConfigs
  